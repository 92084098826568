<template>
  <div style="margin-bottom: 20px;" >
    <p>{{ element.config.question}}</p>
    <b-form-checkbox
      v-if="element.config.multiple"
      v-for="(o, i) in element.config.options"
      :key="i"
      v-model="checkValue"
      :value="o.value"
    >
      {{ o.label }}
    </b-form-checkbox>
    <b-form-radio
      v-if="!element.config.multiple"
      v-for="(o, i) in element.config.options"
      :key="i"
      v-model="checkValue"
      :value="o.value"
    >
      {{ o.label }}
    </b-form-radio>
  </div>
</template>

<script>

import {
  BFormCheckbox,
  BFormRadio,
} from 'bootstrap-vue'
export default {
  components: {
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      blockused: '',
      checkValue: [],
    }
  },
  methods: {
    printBlock(block) {
      this.blockused = block.id
      return block.name
    },
  },
}
</script>
