<template>
  <b-container v-if="prepareTask">
    <b-row>
      <b-col cols="12">
        <b-card class="height-card">
          <b-row>
            <b-col cols="12">
              <h5 class="mb-1 font-weight-bolder">
                {{ task.name }}
              </h5>
            </b-col>
            <b-col cols="12" lg="3" class="d-flex pb-1">
              <strong class="mr-1">{{ $t("Usuarios") }}: </strong>
              <div>
                <div 
                  v-for="(user, i) in task.users"
                  :key="i"
                >
                  <b-link
                    :to="{
                      name: 'viewUser',
                      params: { id: user.id },
                    }"
                  >
                    {{ user.name }}
                    {{ user.surname }}
                  </b-link>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="3">
              <p>
                <strong>{{ $t("Prioridad") }}: </strong
                ><span v-if="task.priority">{{
                  $t("priority." + task.priority)
                }}</span>
              </p>
            </b-col>
            <b-col cols="12" lg="3">
              <p>
                <strong>{{ $t("Evento") }}: </strong
                ><span v-if="task.event">{{ task.event.name }}</span>
              </p>
            </b-col>
            <b-col cols="12" lg="3">
              <p>
                <strong>{{ $t("Formulario") }}: </strong>
                <b-link 
                  v-if="task.form"
                  @click="previewForm(task.form.id)"
                >
                  {{ task.form.name }}
                </b-link>
              </p>
            </b-col>
            <b-col cols="12" lg="3">
              <p>
                <strong>{{ $t("Estado") }}: </strong>
                <b-badge
                  v-if="task.status"
                  :variant="statusTasks[task.status.alias]"
                >
                  {{ $t(`status.tasks.${task.status.alias}`) }}
                </b-badge>
              </p>
            </b-col>
            <b-col v-if="task.project" cols="12" lg="6" class="d-none">
              <p>
                <strong>{{ $t("Proyecto") }}: </strong>{{ task.project.name }}
              </p>
            </b-col>
            <b-col cols="12" lg="4">
              <p>
                <strong>{{ $t("Fechas") }}: </strong
                >{{ TimestampToFlatPickrWithTime(task.date_ini) }}
                <span
                  v-if="
                    task.date_fin &&
                    task.date_fin !== 'null' &&
                    task.date_fin != null
                  "
                  >- {{ TimestampToFlatPickrWithTime(task.date_fin) }}</span
                >
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="8"  v-if="task.description || task.documents.length">
        <b-card class="height-card">
          <h5 class="mb-1 font-weight-bolder">
            {{ $t("DescripcionDocumentos") }}
          </h5>
          <b-row>
            <b-col v-if="task.description" cols="12" class="mb-1 card-task">
              <div v-html="task.description" />
            </b-col>
            <b-col v-if="task.documents && task.documents.length > 0" cols="12">
              <div class="mb-1">
                <p class="font-weight-bolder mb-50">
                  {{ $t("documentacion") }}
                </p>
                <p v-for="d in task.documents" :key="d.id">
                  <b-link :href="d.path" target="_blank" class="mr-1 mb-1 link">
                    {{ d.name }}
                  </b-link>
                </p>
              </div>
            </b-col>
            <b-col v-if="task.parte_trabajo" cols="12">
              <div class="mb-1">
                <p class="font-weight-bolder mb-50">
                  {{ $t("parteTrabajo") }}
                </p>
                <b-link
                  :href="task.parte_trabajo"
                  target="_blank"
                  class="mr-1 mb-1 link"
                >
                  {{ $t("parteTrabajo") }}
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col v-if="task.tag" cols="12" lg="4" class="d-lg-block d-none">
        <b-card class="height-card">
          <h5 class="mb-1 font-weight-bolder">
            {{ $t("QR") }}
          </h5>
          <div class="text-center">
            <img :src="task.tag.image_url" class="img-fluid" />
          </div>
        </b-card>
      </b-col>

      <b-col v-if="task.objects.length" cols="12">
        <b-card>
          <b-row>
            <b-col v-if="ifAsset" cols="12" class="mt-2">
              <h5 class="mb-1 font-weight-bolder">
                {{ $t("Activos") }}
              </h5>
              <b-list-group>
                <b-list-group-item
                  v-for="(p, index) in task.objects"
                  v-if="p.type === 'asset'"
                  :key="index"
                >
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-link
                        v-if="isMobile()"
                        :to="{
                          name: 'publicViewAsset',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <span
                          v-if="
                            task.event.alias == 'review_asset' &&
                            p.object.last_review_date > task.date_ini
                          "
                          class="mr-1"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            size="20"
                            class="text-success"
                          />
                        </span>
                        <span
                          v-else-if="task.event.alias == 'review_asset'"
                          class="mr-1"
                        >
                          <feather-icon icon="CircleIcon" size="20" />
                        </span>

                        <div
                          :style="`background-image: url('${
                            p.object.image ? p.object.image : defaultImage
                          }');`"
                          class="d-inline-block img-product-list mr-50"
                        />
                        <div>
                          <p
                            class="ficha-info_serie mb-0"
                            v-if="
                              p.object.serial_number &&
                              p.object.serial_number != -1
                            "
                          >
                            {{ p.object.serial_number }}
                          </p>
                          <p class="m-0">
                            {{ p.object.name }}
                          </p>
                          <b-badge
                            v-if="p.object.status"
                            :variant="statusAssets[p.object.status.alias]"
                          >
                            {{ $t("status.assets." + p.object.status.alias) }}
                          </b-badge>
                        </div>
                      </b-link>
                      <b-link
                        v-else
                        :to="{
                          name: 'viewAsset',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <span
                          v-if="
                            task.event.alias == 'review_asset' &&
                            p.object.last_review_date > task.date_ini
                          "
                          class="mr-1"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            size="20"
                            class="text-success"
                          />
                        </span>
                        <span
                          v-else-if="task.event.alias == 'review_asset'"
                          class="mr-1"
                        >
                          <feather-icon icon="CircleIcon" size="20" />
                        </span>
                        <div
                          :style="`background-image: url('${
                            p.object.image ? p.object.image : defaultImage
                          }');`"
                          class="d-inline-block img-product-list mr-50"
                        />
                        <div>
                          <p
                            class="ficha-info_serie mb-0"
                            v-if="
                              p.object.serial_number &&
                              p.object.serial_number != -1
                            "
                          >
                            {{ p.object.serial_number }}
                          </p>
                          <p class="m-0">
                            {{ p.object.name }}
                          </p>
                          <b-badge
                            v-if="p.object.status"
                            :variant="statusAssets[p.object.status.alias]"
                          >
                            {{ $t("status.assets." + p.object.status.alias) }}
                          </b-badge>
                        </div>
                      </b-link>
                    </b-col>
                    <b-col
                      v-if="p.object.last_review_date"
                      cols="12"
                      md="6"
                      class="d-flex align-items-center mt-1 mt-md-0"
                    >
                      <strong>{{ $t("Última revisión") }}: </strong
                      ><span class="ml-50">{{
                        TimestampToFlatPickr(p.object.last_review_date)
                      }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col v-if="ifProduct" cols="12" class="mt-2">
              <h5 class="mb-1 font-weight-bolder">
                {{ $t("Productos") }}
              </h5>
              <b-list-group>
                <b-list-group-item
                  v-for="(p, index) in task.objects"
                  v-if="p.type === 'product'"
                  :key="index"
                >
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-link
                        v-if="isMobile()"
                        :to="{
                          name: 'publicViewProduct',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div
                          v-if="p.object.imagen"
                          :style="`background-image: url('${p.object.imagen}');`"
                          class="d-inline-block img-product-list mr-50"
                        />
                        <div>
                          <p class="m-0">
                            {{ p.object.CodAndName }}
                          </p>
                        </div>
                      </b-link>
                      <b-link
                        v-else
                        :to="{
                          name: 'viewProduct',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div
                          v-if="p.object.imagen"
                          :style="`background-image: url('${p.object.imagen}');`"
                          class="d-inline-block img-product-list mr-50"
                        />
                        <div>
                          <p class="m-0">
                            {{ p.object.CodAndName }}
                          </p>
                        </div>
                      </b-link>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col v-if="ifSet" cols="12" class="mt-2">
              <h5 class="mb-1 font-weight-bolder">
                {{ $t("Agrupaciones") }}
              </h5>
              <b-list-group>
                <b-list-group-item
                  v-for="(p, index) in task.objects"
                  v-if="p.type === 'set'"
                  :key="index"
                >
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-link
                        v-if="isMobile()"
                        :to="{
                          name: 'publicViewSet',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>
                          <p class="m-0">
                            {{ p.object.name }}
                          </p>
                        </div>
                      </b-link>
                      <b-link
                        v-else
                        :to="{
                          name: 'viewSet',
                          params: { id: p.object.id },
                        }"
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>
                          <p class="m-0">
                            {{ p.object.name }}
                          </p>
                        </div>
                      </b-link>
                    </b-col>
                    <b-col
                      v-if="p.object.last_review_date"
                      cols="12"
                      md="6"
                      class="d-flex align-items-center mt-1 mt-md-0"
                    >
                      <strong>{{ $t("Última revisión") }}: </strong
                      ><span class="ml-50">{{
                        TimestampToFlatPickr(p.object.last_review_date)
                      }}</span>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <ShowPreviewModal />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import {
//   BBadge,
//   BRow,
//   BCol,
//   BCard,
//   BListGroupItem,
//   BListGroup,
//   BContainer,
//   BLink,
//   BButton,
//   BFormRadioGroup,
// } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  TimestampToFlatPickrWithTime,
  TimestampToFlatPickr,
} from "@/libs/helpers";
import { config } from "@/shared/app.config";
import ShowPreviewModal from "@/components/forms/modals/ShowPreviewModal.vue"

export default {
  components: {
    ShowPreviewModal
    // BButton,
    // BBadge,
    // BLink,
    // BListGroup,
    // BRow,
    // BCol,
    // BCard,
    // BListGroupItem,
    // BContainer,
    // BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusTasks: config.statusTasksVariants,
      statusAssets: config.statusAssetsVariants,
      notify: null,
      prepareTask: false,
      defaultImage: require("@/assets/images/default/asset.png"),
      optionsNotify: [
        { text: "Correo electrónico", value: "first" },
        { text: "SMS", value: "second" },
        { text: "Push móvil", value: "third" },
      ],
      ifAsset: 0,
      ifProduct: 0,
      ifSet: 0,
    };
  },
  computed: {
    ...mapGetters({
      task: "tasks/getTask",
    }),
    showNotifications() {
      if (this.$route.meta.layout && this.$route.meta.layout == "full") {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      getTask: "tasks/getTask",
      getForm: 'forms/getForm',
    }),
    isMobile() {
      if (this.$route.name === "publicViewTask") {
        return true;
      }
      return false;
    },
    TimestampToFlatPickrWithTime(f) {
      return TimestampToFlatPickrWithTime(f);
    },
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f);
    },
    async previewForm(id) {
      await this.getForm(id)
      this.$bvModal.show('showPreviewModal')
    },
  },
  async created() {
    await this.getTask(this.$route.params.id);
    if (this.task.objects.length) {
      this.task.objects.forEach(function (entry) {
        if (entry.type === "asset") {
          this.ifAsset = 1;
        }
        if (entry.type === "product") {
          this.ifProduct = 1;
        }
        if (entry.type === "set") {
          this.ifSet = 1;
        }
      }, this);
    }
    this.prepareTask = true;
  },
};
</script>
