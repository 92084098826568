<template>
  <div>
    <b-modal
      id="showPreviewModal"
      hide-footer
      centered
      size="lg"
      :title="form.name"
    >
      <div v-if="form.formElements">
        <div
          v-for="(e, i) in form.formElements"
          :key="i"
          style="margin-bottom: 20px"
        >
          <template v-if="shouldRender(e)">
            <p
              v-if="shouldPrintBlock(e)"
              style="font-weight: 700; font-size: 18px"
            >
              {{ e.block_info.name }}
            </p>
            <component :is="getComponent(e.alias)" :element="e" />
          </template>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Check from '@/components/forms/components/Check.vue'
import TextInput from '@/components/forms/components/TextInput.vue'
import Sign from '@/components/forms/components/Sign.vue'
import Archive from '@/components/forms/components/Archive.vue'
import TextBlock from '@/components/forms/components/TextBlock.vue'
import TextArea from '@/components/forms/components/TextArea.vue'

export default {
  components: {
    Check,
    TextInput,
    Sign,
    Archive,
  },
  data() {
    return {
      printedBlocks: new Set(),
    }
  },
  computed: {
    ...mapGetters({
      form: 'forms/getForm',
    }),
  },
  methods: {
    shouldRender(element) {
      return ['check', 'text_input', 'signature', 'archive', 'text_area', 'text_block'].includes(element.alias)
    },
    shouldPrintBlock(element) {
      // Verificar si el bloque ya se ha impreso anteriormente
      if (this.printedBlocks.has(element.block_info.id)) {
        return false
      } else {
        this.printedBlocks.add(element.block_info.id)
        return true
      }
    },
    getComponent(alias) {
      switch (alias) {
        case 'check':
          return Check
        case 'text_input':
          return TextInput
        case 'signature':
          return Sign
        case 'archive':
          return Archive
        case 'text_block':
          return TextBlock
        case 'text_area':
          return TextArea
        default:
          return null
      }
    },
  },
};
</script>
